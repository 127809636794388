import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export default function Markdown({
  className,
  source,
  renderers = {},
  ...rest
}) {
  return (
    <ReactMarkdown
      className={className}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: function H1({ children }) {
          return (
            <h1 className="mb-6 font-serif text-2xl lg:text-3xl">{children}</h1>
          )
        },
        h2: function H2({ children }) {
          return (
            <h2 className="mb-6 font-serif text-2xl lg:text-3xl">{children}</h2>
          )
        },
        h3: function H3({ children }) {
          return (
            <h3 className="mb-6 font-serif text-2xl lg:text-3xl">{children}</h3>
          )
        },
        h4: function H4({ children }) {
          return (
            <h4 className="mb-6 font-serif text-2xl lg:text-3xl">{children}</h4>
          )
        },
        h5: function H5({ children }) {
          return (
            <h5 className="mb-6 font-serif text-2xl lg:text-3xl">{children}</h5>
          )
        },
        h6: function H6({ children }) {
          return (
            <h6 className="mb-6 font-serif text-2xl lg:text-3xl">{children}</h6>
          )
        },
        link: function Link({ children, ...rest }) {
          return (
            // @ts-ignore
            <a {...rest} className="font-medium underline">
              {'https://charmelle.london/' + children}
            </a>
          )
        },
        a: function A({ children, ...rest }) {
          return (
            // @ts-ignore
            <a {...rest} className="font-medium underline">
              {'https://charmelle.london/' + children}
            </a>
          )
        },
        strong: function Strong({ children }) {
          return <strong className="font-medium">{children}</strong>
        },
        p: function P({ children }) {
          return <p className="mb-4 text-lg">{children}</p>
        },
        ...renderers,
      }}
      {...rest}
    >
      {source}
    </ReactMarkdown>
  )
}
